import './App.css';

function App() {
  return (
    <div style={{backgroundColor: 'black', height: '100vh', width: '100vw', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
      <style>
        @import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100..700;1,100..700&display=swap');
        </style>
      <img src="/bg.svg" alt="bg"  width={'30%'}/>
      <div className='button' 
      onClick={() => window.location.href = 'https://aigram.pro'}
      >
        <span className='text'>Connect </span>
      </div>
    </div>
  );
}

export default App;
